<template>
    <v-dialog v-if="isCompleted">
        <template #activator="{ attrs, on }">
            <span v-bind="attrs" v-on="on"></span>
            <slot name="activator" v-bind="{ attrs: { disabled, loading }, on: { click } }" />
        </template>
    </v-dialog>
</template>

<script>
import { initPurchase, PURCHASE_PURCHASE_STATUSES } from "@/assets/variables";

import ReviewForm from "@/components/client/shop/reviews/review-form.vue";
import InquireForm from "@/components/client/shop/inquires/inquire-form.vue";
import api from "@/api";

export default {
    components: {
        ReviewForm,
        InquireForm,
    },
    props: {
        value: { type: Object, default: initPurchase }, // purchase
    },
    data: () => ({
        loading: false,
    }),
    computed: {
        cart() {
            return Object.assignDefined(
                {},
                {
                    _product: this.value._product,
                    _option: this.value._option,
                    _supply: this.value._supply,
                    _related: this.value._related,
                    amount: 1,
                }
            );
        },
        disabled() {
            return this.value.product.stock < 1;
        },
        isCompleted() {
            return this.value.purchaseStatus == PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value;
        },
    },
    methods: {
        async click() {
            if (this.loading) return;
            else this.loading = true;
            try {
                const { carts } = await api.v1.me.carts.post({
                    carts: [this.cart],
                    shippingOption: this.value.shippingOption,
                });
                this.$store.dispatch("addToCart", carts);
                this.$emit("showModalCart");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>

<template>
    <v-card rounded="" color="grey lighten-5" class="overflow-hidden">
        <purchase-status-navigation v-bind="{ loading, hideOverall }" />
        <template v-if="!hideFilters">
            <v-divider class="grey lighten-4" />
            <purchase-status-filter v-bind="{ loading }" />
        </template>
    </v-card>
</template>

<script>
import PurchaseStatusFilter from "./purchase-status-filter.vue";
import PurchaseStatusNavigation from "./purchase-status-navigation.vue";
export default {
    components: {
        PurchaseStatusNavigation,
        PurchaseStatusFilter,
    },
    props: {
        loading: { type: Boolean, default: false },
        hideOverall: { type: Boolean, default: false },
        hideFilters: { type: Boolean, default: false },
    },
};
</script>

<style></style>

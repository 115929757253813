<template>
    <client-page>
        <mypage-layout title="환불계좌 관리">
            <v-data-table v-bind="{ items, headers, loading }" :headers="headers" :items="items" dense hide-default-footer disable-sort disable-filtering disable-pagination no-data-text="등록된 계좌가 없습니다" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.a`]="{ item }">
                    <div class="grey--text text--darken-4 font-weight-medium">KB국민은행</div>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-row class="row--xxs" justify="center">
                        <v-col cols="4" md="auto" class="mw-100 flex-grow-1 flex-md-grow-0 flex-shrink-0">
                            <v-btn v-bind="{ ...btn_tertiary, ...$attrs }" block class="v-size--xx-small">삭제</v-btn>
                        </v-col>
                        <v-col cols="4" md="auto" class="mw-100 flex-grow-1 flex-md-grow-0 flex-shrink-0">
                            <v-btn v-bind="{ ...btn_tertiary, ...$attrs }" block class="v-size--xx-small">수정</v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>

            <div class="btn-wrap">
                <v-row align="center" class="row--xs">
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn v-bind="{ ...btn_secondary, ...$attrs }">계좌 등록하기</v-btn>
                    </v-col>
                </v-row>
            </div>

            <!-- <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small title="환불계좌 정보">
                    <span class="font-size-12 red--text">*본인 명의의 통장으로만 입금 받으실 수 있습니다.</span>
                </tit-wrap-x-small>
                <v-row class="row--xs">
                    <v-col cols="12">
                        <autocomplete-bank v-bind="{ ...attrs_input, loading }" placeholder="은행사 선택" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-bind="{ ...attrs_input, loading }" placeholder="계좌번호를 입력하세요." />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-bind="{ ...attrs_input, loading }" placeholder="예금주명을 입력하세요." />
                    </v-col>
                </v-row>
            </page-section> -->

            <div class="pagination-wrap pagination-wrap--sm">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { btn_primary, btn_secondary, btn_tertiary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";

const headers = [
    { width: "140", align: "center", text: "은행명", value: "a" },
    { width: "auto", align: "center", text: "계좌번호", value: "b" },
    { width: "20%", align: "center", text: "예금주", value: "c" },
    { width: "14%", align: "center", text: "관리", value: "actions" },
];

export default {
    components: {
        ClientPage,
        MypageLayout,
    },
    data: () => ({
        btn_primary,
        btn_secondary,
        btn_tertiary,

        headers,

        loading: false,
    }),
    computed: {},
    mounted() {
        this.init();
    },
    methods: {
        init() {},
    },
};
</script>

<template>
    <u-dialog v-model="shows" persistent title="리뷰 작성" width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <div>
            <page-section class="page-section--xs pt-0">
                <review-purchase-item v-bind="{ product, purchase }" />
            </page-section>

            <v-divider />

            <!-- S: 리뷰포인트 정보 -->
            <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small title="리뷰포인트 정보"></tit-wrap-x-small>
                <v-card rounded="md" color="grey lighten-5">
                    <div class="pa-16px">
                        <div>
                            <v-row no-gutters justify="space-between" class="txt txt--xs">
                                <v-col cols="auto"> 리뷰 작성 시 </v-col>
                                <v-col cols="auto">
                                    <span class="font-weight-medium grey--text text--darken-4"> {{ (Math.floor(salePrice / 1000) * 10)?.format?.() }}p 적립</span>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="mt-8px">
                            <v-row no-gutters justify="space-between" class="txt txt--xs">
                                <v-col cols="auto"> 포토리뷰 작성 시 </v-col>
                                <v-col cols="auto">
                                    <span class="font-weight-medium grey--text text--darken-4"> {{ (Math.floor(salePrice / 1000) * 10 * 2)?.format?.() }}p 적립</span>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-card>
            </page-section>
            <!-- E: 리뷰포인트 정보 -->

            <!-- S: 상품 만족도 -->
            <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small>
                    <template #titleContents>
                        <h3 class="txt txt--xs txt--dark font-weight-medium">상품 만족도 <span class="red--text">*</span></h3>
                    </template>
                </tit-wrap-x-small>
                <v-card rounded="md" color="grey lighten-5">
                    <div class="pa-16px pa-md-32px">
                        <div class="text-center">
                            <div class="txt txt--sm txt--dark font-weight-medium">이 상품에 대해서 얼마나 만족하시나요?</div>
                            <div class="font-size-20 font-size-md-36 font-weight-bold">{{ form.star || 0 }}</div>
                            <v-rating v-model="form.star" half-increments color="grey darken-4" background-color="grey darken-4" />
                            <div class="txt txt--xs txt--light">별점을 선택해 주세요.</div>
                        </div>
                    </div>
                </v-card>
            </page-section>
            <!-- E: 상품 만족도 -->

            <!-- S: 리뷰 작성 -->
            <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small>
                    <template #titleContents>
                        <h3 class="txt txt--xs txt--dark font-weight-medium">리뷰 작성 <span class="red--text">*</span></h3>
                    </template>
                </tit-wrap-x-small>
                <v-textarea v-model="form.content" v-bind="{ ...attrs_input, loading }" placeholder="리뷰를 작성해 주세요." />
            </page-section>
            <!-- E: 리뷰 작성 -->

            <!-- S: 사진 첨부 -->
            <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small>
                    <template #titleContents>
                        <h3 class="txt txt--xs txt--dark font-weight-medium">사진 첨부<span class="txt--light font-size-12 font-weight-regular pl-8px">사진은 최대 20MB 이하의 JPG, PNG, GIF 파일 10장까지 첨부 가능합니다.</span></h3>
                    </template>
                </tit-wrap-x-small>
                <form-files v-model="form.images" />
                <div class="pt-12px">
                    <v-card rounded="md" color="grey lighten-5">
                        <div class="pa-16px">
                            <div class="font-size-12 red--text">*사진 첨부 시 포인트를 추가 적립해 드리며, 제품과 무관한 사진 등록 시 포인트 차감의 사유가 될 수 있습니다.</div>
                        </div>
                    </v-card>
                </div>
            </page-section>
            <!-- E: 사진 첨부 -->
        </div>
        <template #actions>
            <v-row class="row--sm">
                <v-col>
                    <v-btn v-bind="{ ...btn_tertiary, ...$attrs }" block class="v-size--xx-large">취소</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-bind="{ ...btn_primary, ...$attrs }" block class="v-size--xx-large" @click="save">{{ isCreate ? "작성" : "수정" }}</v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import { attrs_input, initShopReview, USER_TYPES } from "@/assets/variables";
import { btn_primary, btn_tertiary } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import TitWrapXSmall from "../../dumb/tit-wrap-x-small.vue";
import ReviewPurchaseItem from "./review-purchase-item.vue";
import FormFiles from "@/components/client/dumb/form-files.vue";
import api from "@/api";

export default {
    components: {
        PageSection,
        UDialog,
        TitWrapXSmall,
        ReviewPurchaseItem,
        FormFiles,
    },
    props: {
        value: { type: Object, default: initShopReview }, // review
        purchase: { type: Object, default: () => ({}) },
    },
    data: () => ({
        btn_primary,
        btn_tertiary,

        form: initShopReview(),

        shows: false,
        loading: false,

        attrs_input,
    }),
    computed: {
        isCreate() {
            return !this.form?._id;
        },
        product() {
            return this.purchase?.product || {};
        },
        salePrice() {
            const { salePrice = 0, discountPrice = 0, couponPrice = 0 } = this.purchase || {};

            return salePrice - discountPrice - couponPrice;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            this.init();
        },
    },
    methods: {
        init() {
            this.form = initShopReview({
                ...this.value,
                _product: this.product?._id || this.value?._product || null,
                _purchase: this.purchase?._id || this.value?._purchase || null,
                product: this.product || this.value?.product || null,
            });
        },
        validates() {
            try {
                if (!this.form.star) throw new Error("별점을 선택해 주세요");

                return true;
            } catch (error) {
                this.$handleError(error);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                const { post, put } = api.v1.me.reviews;

                let { _images = [], images = [], ...form } = this.form || {};
                const { review } = await (this.isCreate ? post : put)(form);

                await Promise.all(_images.map(async (_id) => await api.v1.me.reviews.images.delete({ _id, _review: review._id })));

                for (const index in images) {
                    let image = images[index];
                    images[index] = (await api.v1.me.reviews.images.post({ _review: review._id, index }, image))?.image;
                }
                review.images = images;
                review._images = images?.map?.(({ _id }) => _id) || [];

                this.$emit("input", review);
                alert("저장되었습니다");
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>

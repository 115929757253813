<template>
    <item-dialog v-bind="{ coupon }">
        <template #activator="{ attrs, on }">
            <v-card outlined class="fill-height overflow-hidden" v-bind="{ ...attrs, loading }" @click="on.click">
                <v-row no-gutters align="stretch" class="fill-height">
                    <v-col cols="12" md="">
                        <div class="d-flex flex-column justify-space-between fill-height pa-12px pa-md-20px" v-bind="{ disabled }">
                            <item-chip v-bind="{ coupon }" class="mb-8px" />
                            <item-name v-bind="{ coupon }" class="mb-12px" />
                            <item-price v-bind="{ coupon }" />
                            <item-desc v-bind="{ coupon }" mode="user-coupons" class="font-size-12 grey--text mt-12px" />
                        </div>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div class="d-flex flex-column h-100 grey lighten-5 pa-12px">
                            <div class="text-right mt-n12px mr-n12px mb-md-n40px pb-12px">
                                <v-btn icon tile @click.prevent.stop.capture="remove">
                                    <v-icon color="grey lighten-3">mdi-close</v-icon>
                                </v-btn>
                            </div>
                            <div class="ma-md-auto" v-if="coupon.code">
                                <div class="txt txt--xs text-md-center">
                                    쿠폰번호<span class="d-md-none"> : </span><br class="d-none d-md-block" />
                                    <span class="txt--dark">{{ coupon.code }}</span>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </item-dialog>
</template>

<script>
import api from "@/api";
import { initUserCoupon } from "@/assets/variables";

import ItemChip from "./item/item-chip.vue";
import ItemName from "./item/item-name.vue";
import ItemDesc from "./item/item-desc.vue";
import ItemPrice from "./item/item-price.vue";
import ItemDialog from "./item/item-dialog.vue";

export default {
    components: {
        ItemChip,
        ItemName,
        ItemDesc,
        ItemPrice,
        ItemDialog,
    },
    props: {
        coupon: { type: Object, default: initUserCoupon },
    },
    data: () => ({
        loading: false,
    }),
    computed: {
        disabled() {
            return this.coupon.isUsed;
        },
    },
    methods: {
        async remove() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { coupon } = await api.v1.me.coupons.delete({
                    _id: this.coupon._id,
                });
                this.loading = false;
                this.$emit("search");
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>
<template>
    <v-card color="grey lighten-5">
        <div class="pa-18px pa-md-24px">
            <v-row align="center">
                <v-col cols="12">
                    <v-row align="center" justify="center" class="row--sm">
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt txt--xs">선택상품금액</span>
                                </v-col>
                                <v-col cols="auto">
                                    <strong class="d-inline-flex align-center font-size-16"> {{ productPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt txt--xs">배송비</span>
                                    <!-- <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" small color="grey lighten-3" class="ml-4px cursor-pointer">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>{{ deliveryMessage }}</span>
                                    </v-tooltip> -->
                                </v-col>
                                <v-col cols="auto">
                                    <strong class="d-inline-flex align-center font-size-16">{{ (servicePrice + deliveryPrice).format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></strong>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt txt--xs">할인금액</span>
                                </v-col>
                                <v-col cols="auto">
                                    <strong class="d-inline-flex align-center font-size-16 red--text"> <span class="d-xl-none">-</span>{{ discountPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt txt--xs">등급할인</span>
                                </v-col>
                                <v-col cols="auto">
                                    <strong class="d-inline-flex align-center font-size-16 red--text"> <span class="d-xl-none">-</span>{{ levelDiscountPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt txt--xs">주문금액</span>
                                </v-col>
                                <v-col cols="auto">
                                    <strong class="d-inline-flex align-center font-size-16">{{ totalPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-divider />
                </v-col>
                <v-col cols="12">
                    <v-row align="center" justify="end" class="row--sm">
                        <v-col cols="12" sm="auto">
                            <v-btn v-bind="{ ...btn_secondary, ...$attrs }" x-large class="w-100 min-w-sm-200px" @click="$emit('order', selected)">선택 주문하기</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { btn_secondary } from "@/assets/variables";

export default {
    props: {
        selected: { type: Array, default: () => [] },
    },
    data: () => ({
        btn_secondary,
    }),
    computed: {
        ...mapGetters(["userLevel"]),
        productPrice() {
            return this.$getProductPrice(this.selected);
        },
        servicePrice() {
            return this.$getServicePrice(this.selected);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.selected);
        },
        discountPrice() {
            return this.$getDiscountPrice(this.selected);
        },
        levelDiscountPrice() {
            return this.$getLevelDiscountPrice(this.selected, this.userLevel);
        },
        totalPrice() {
            return this.productPrice + this.servicePrice + this.deliveryPrice - this.discountPrice - this.levelDiscountPrice;
        },
    },
};
</script>

<style lang="scss" scoped>
.cart-foot-row {
    &__arithmetic {
        display: none;
    }
}
@media (min-width: 1200px) {
    .cart-foot-row {
        justify-content: center !important;
        margin: -20px -2px;
        &__calculation,
        &__total {
            padding: 20px 2px;
        }
        &__divider {
            display: none;
        }
        &__arithmetic {
            display: block;
        }
    }
}
</style>

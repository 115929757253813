var render = function render(){
  var _vm$form, _vm$form$createdAt, _vm$form$createdAt$to;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "title": _vm.title + ' 철회하기',
      "persistent": "",
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm"
        }, [_c('v-col', [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.close
          }
        }, 'v-btn', Object.assign({}, _vm.btn_tertiary, _vm.$attrs), false), [_vm._v("취소")])], 1), _c('v-col', [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.save
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v(" " + _vm._s(_vm.title) + " 철회 ")])], 1)], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', _vm._b({}, 'div', {
    loading: _vm.loading
  }, false), [_c('div', {
    staticClass: "tit tit--xs pb-12px"
  }, [_vm._v(_vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$createdAt = _vm$form.createdAt) === null || _vm$form$createdAt === void 0 ? void 0 : (_vm$form$createdAt$to = _vm$form$createdAt.toDate) === null || _vm$form$createdAt$to === void 0 ? void 0 : _vm$form$createdAt$to.call(_vm$form$createdAt)))]), _c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('purchase-item', {
    attrs: {
      "value": _vm.form,
      "mode": "view",
      "withQuantity": ""
    }
  }), _c('v-divider'), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": _vm.typeText + '유형'
    }
  }), _c('v-select', _vm._b({
    attrs: {
      "placeholder": `${_vm.title} 유형을 선택해주세요`,
      "items": _vm.claimReasonItems,
      "readonly": ""
    },
    model: {
      value: _vm.form.claimReason,
      callback: function ($$v) {
        _vm.$set(_vm.form, "claimReason", $$v);
      },
      expression: "form.claimReason"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": _vm.typeText + '사유'
    }
  }), _c('v-textarea', _vm._b({
    attrs: {
      "placeholder": `${_vm.title} 사유를 입력해주세요`,
      "readonly": ""
    },
    model: {
      value: _vm.form.claimReasonDetails,
      callback: function ($$v) {
        _vm.$set(_vm.form, "claimReasonDetails", $$v);
      },
      expression: "form.claimReasonDetails"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-18px pa-md-24px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs"
  }, [_vm._v("선택상품금액")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', {
    staticClass: "d-inline-flex align-center font-size-16"
  }, [_vm._v(" " + _vm._s(_vm.productPrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs"
  }, [_vm._v("배송비")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', {
    staticClass: "d-inline-flex align-center font-size-16"
  }, [_vm._v(_vm._s((_vm.servicePrice + _vm.deliveryPrice).format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs"
  }, [_vm._v("할인금액")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', {
    staticClass: "d-inline-flex align-center font-size-16 red--text"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("-")]), _vm._v(_vm._s(_vm.discountPrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs"
  }, [_vm._v("등급할인")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', {
    staticClass: "d-inline-flex align-center font-size-16 red--text"
  }, [_c('span', {
    staticClass: "d-xl-none"
  }, [_vm._v("-")]), _vm._v(_vm._s(_vm.levelDiscountPrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs"
  }, [_vm._v("주문금액")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', {
    staticClass: "d-inline-flex align-center font-size-16"
  }, [_vm._v(_vm._s(_vm.totalPrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "w-100 min-w-sm-200px",
    attrs: {
      "x-large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('order', _vm.selected);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary, _vm.$attrs), false), [_vm._v("선택 주문하기")])], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
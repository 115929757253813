var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.SkinComponent, _vm._b({
    tag: "component",
    attrs: {
      "color": "transparent"
    }
  }, 'component', {
    value: _vm.value
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-card flat tile v-bind="{ loading }">
        <template v-if="coupons.length">
            <v-row justify="end" align="center">
                <v-col cols="auto">
                    <span class="font-size-14">
                        총 <span class="secondary--text">{{ summary.totalCount }}</span
                        >개
                    </span>
                </v-col>
                <v-col cols="auto">
                    <v-checkbox v-model="query.isNotUsed" v-bind="{ ...checkbox_primary, ...$attrs }" label="사용된 쿠폰 미노출" />
                </v-col>
                <v-col cols="auto">
                    <v-checkbox v-model="query.isNotExpired" v-bind="{ ...checkbox_primary, ...$attrs }" label="만료된 쿠폰 미노출" />
                </v-col>
            </v-row>
            <v-row>
                <template v-for="coupon in coupons">
                    <v-col cols="12" sm="6" md="12" lg="6" :key="coupon._id">
                        <user-coupon-item v-bind="{ coupon }" v-on="{ search }" />
                    </v-col>
                </template>
            </v-row>
            <div class="pagination-wrap">
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" />
            </div>
        </template>
        <template v-else>
            <div class="text-center">
                <div class="mb-18px mb-md-24px">
                    <v-icon size="60px" color="primary">mdi-ticket-percent</v-icon>
                </div>
                <div class="tit tit--sm">보유 쿠폰이 없습니다.</div>
                <div class="txt txt--sm mt-8px">쿠폰 받기 탭을 확인해주세요.</div>

                <div class="btn-wrap btn-wrap--lg">
                    <v-row justify="center" class="row--sm">
                        <v-col cols="6" sm="auto">
                            <v-btn v-bind="{ ...btn_primary, ...$attrs }" to="/mypage/coupons/download" class="v-size--xx-large w-100 min-w-sm-200px">쿠폰 받기</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </template>
    </v-card>
</template>

<script>
import { initUserCoupon } from "@/assets/variables";
import { btn_primary, checkbox_primary } from "@/assets/variables";

import UserCouponItem from "@/components/client/shop/coupons/user-coupon-item.vue";
import api from "@/api";

export default {
    components: {
        UserCouponItem,
    },
    data: () => ({
        btn_primary,
        checkbox_primary,

        coupons: [],

        page: 1,
        limit: 10,
        summary: { totalCount: 0 },

        query: {
            isUsed: undefined,
            isExpired: undefined,
            isNotUsed: true,
            isNotExpired: true,
        },

        loading: false,
    }),
    computed: {
        items() {
            return [...this.coupons].map(initUserCoupon);
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.query;

            query.isExpired = !query.isNotExpired;
            delete query.isNotExpired;
            if (query.isExpired) delete query.isExpired;

            query.isUsed = !query.isNotUsed;
            delete query.isNotUsed;
            if (query.isUsed) delete query.isUsed;

            query.isDeleted = false;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        page() {
            this.search();
        },
        params() {
            this.search();
        },
        "$route.path"(path) {
            console.log({ path });
            if (path == "/mypage/coupons") this.search();
        },
    },
    methods: {
        async init() {
            this.coupons = [];
            this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, coupons } = await api.v1.me.coupons.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.coupons = coupons;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>

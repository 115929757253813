<template>
    <div class="pb-16px pb-md-20px">
        <v-row align="center" class="row--xs">
            <v-col cols="auto" class="pa-0">
                <v-btn text class="align-center ml-n4" @click="selectAll">
                    <v-simple-checkbox v-bind="{ ...checkbox_primary, ...$attrs }" :value="isAllSelected" class="v-size--large mb-n1" @click="selectAll" />
                    <span class="ml-3"> 전체선택 </span>
                </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-btn v-bind="{ ...btn_tertiary, ...$attrs }" small :disabled="!value.length" @click="$emit('removeSelected')">
                    <span><v-icon color="grey lighten-3" class="mr-6px">mdi-close</v-icon>선택삭제</span>
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn v-bind="{ ...btn_tertiary, ...$attrs }" small :disabled="!carts__outOfStock.length" @click="$emit('removeDisabled')">
                    <span><v-icon color="grey lighten-3" class="mr-6px">mdi-close</v-icon>주문불가삭제</span>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { btn_tertiary, checkbox_primary } from "@/assets/variables";

export default {
    props: {
        value: { type: Array, default: () => [] }, // selected
        carts: { type: Array, default: () => [] },
        carts__outOfStock: { type: Array, default: () => [] },
    },
    data: () => ({
        btn_tertiary,
        checkbox_primary,
    }),
    computed: {
        isAllSelected() {
            return !this.carts.some(({ _id }) => !this.value.some((item) => item._id == _id));
        },
    },
    methods: {
        selectAll() {
            if (this.isAllSelected) this.$emit("input", []);
            else this.$emit("input", [...this.carts]);
        },
    },
};
</script>

<style></style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_vm.coupons.length ? [_c('v-row', {
    attrs: {
      "justify": "end",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v(" 총 "), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.summary.totalCount))]), _vm._v("개 ")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', _vm._b({
    attrs: {
      "label": "사용된 쿠폰 미노출"
    },
    model: {
      value: _vm.query.isNotUsed,
      callback: function ($$v) {
        _vm.$set(_vm.query, "isNotUsed", $$v);
      },
      expression: "query.isNotUsed"
    }
  }, 'v-checkbox', Object.assign({}, _vm.checkbox_primary, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', _vm._b({
    attrs: {
      "label": "만료된 쿠폰 미노출"
    },
    model: {
      value: _vm.query.isNotExpired,
      callback: function ($$v) {
        _vm.$set(_vm.query, "isNotExpired", $$v);
      },
      expression: "query.isNotExpired"
    }
  }, 'v-checkbox', Object.assign({}, _vm.checkbox_primary, _vm.$attrs), false))], 1)], 1), _c('v-row', [_vm._l(_vm.coupons, function (coupon) {
    return [_c('v-col', {
      key: coupon._id,
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "12",
        "lg": "6"
      }
    }, [_c('user-coupon-item', _vm._g(_vm._b({}, 'user-coupon-item', {
      coupon
    }, false), {
      search: _vm.search
    }))], 1)];
  })], 2), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)] : [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "mb-18px mb-md-24px"
  }, [_c('v-icon', {
    attrs: {
      "size": "60px",
      "color": "primary"
    }
  }, [_vm._v("mdi-ticket-percent")])], 1), _c('div', {
    staticClass: "tit tit--sm"
  }, [_vm._v("보유 쿠폰이 없습니다.")]), _c('div', {
    staticClass: "txt txt--sm mt-8px"
  }, [_vm._v("쿠폰 받기 탭을 확인해주세요.")]), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "to": "/mypage/coupons/download"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("쿠폰 받기")])], 1)], 1)], 1)])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
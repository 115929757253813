<template>
    <v-tab-item :value="tab">
        <tit-wrap-subtitle titSize="xs" class="mb-0 pb-12px pb-md-20px">
            <template #tit>비밀번호 입력</template>
            <template #subTit>개인정보 확인을 위해 비밀번호를 입력해 주세요.</template>
        </tit-wrap-subtitle>

        <vertical-form-table v-model="form" v-bind="{ items }">
            <template slot="아이디"> {{ username }} </template>
        </vertical-form-table>

        <div class="btn-wrap btn-wrap--lg">
            <v-row justify="center" class="row--sm">
                <v-col cols="6" sm="auto">
                    <v-btn v-bind="{ ...btn_primary, ...$attrs, loading }" class="v-size--xx-large w-100 min-w-sm-200px" @click="verify">확인</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-tab-item>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { attrs_input } from "@/assets/variables";
import { btn_primary } from "@/assets/variables";

import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

const items = [
    {
        term: "아이디",
    },
    {
        term: "비밀번호",
        type: "password",
        key: "password",
        ...attrs_input,
    },
];

export default {
    components: {
        TitWrapSubtitle,
        VerticalFormTable,
    },
    props: {
        tab: { type: String, default: "verify" },
    },
    data: () => ({
        btn_primary,

        form: {
            password: "",
        },

        items,
        loading: false,
    }),
    computed: {
        username() {
            return this.$store.state.user?.username;
        },
    },
    methods: {
        async verify() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { password } = this.form;
                password = CryptoAES.encrypt(password);
                await api.v1.me.verify({ password });
                this.$emit("next");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>

<template>
    <v-data-table :items="notifications" :headers="headers" dense hide-default-footer disable-sort disable-filtering disable-pagination @click:row="(any, { item }) => showBoard(item)" no-data-text="등록된 공지사항이 없습니다" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer">
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
        <template #[`item.subject`]="{ item }">
            <div data-idx="5" class="text-truncate">{{ item.subject }}</div>
        </template>

        <template #footer>
            <!-- 개발확인 -->
            <!-- S: 검색 -->
            <div class="mt-20px mt-40px">
                <v-card color="grey lighten-5">
                    <div class="pa-20px">
                        <v-row align="center" justify="center" class="row--sm">
                            <v-col cols="12" sm="auto">
                                <v-row align="center" class="row--xs">
                                    <v-col cols="auto">
                                        <v-select dense outlined hide-details item-text="text" item-value="type" placeholder="선택" class="w-120px" />
                                    </v-col>
                                    <v-col cols="">
                                        <v-text-field dense outlined hide-details placeholder="검색어를 입력하세요" />
                                    </v-col>
                                    <v-col cols="12" sm="auto">
                                        <v-btn v-bind="{ ...btn_primary3, ...$attrs }" class="w-100 w-sm-80px">검색</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </div>
            <!-- E: 검색 -->

            <!-- S: 페이징 -->
            <div class="pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
            <!-- E: 페이징 -->
        </template>
    </v-data-table>
</template>

<script>
import api from "@/api";
import { btn_primary3 } from "@/assets/variables";

import PaginationComponent from "@/components/client/pagination-component.vue";

const headers = [
    { align: "center", text: "제목", value: "subject" },
    { width: "16%", align: "center", text: "작성자", value: "writer", formatter: (value) => value?.name || "-" },
    { width: "16%", align: "center", text: "작성일", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-" },
    { width: "16%", align: "center", text: "조회수", value: "viewCount" },
];

export default {
    components: {
        PaginationComponent,
    },
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        notifications: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,
        btn_primary3,

        loading: false,
    }),
    computed: {
        items() {
            return [...this.notifications].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    methods: {
        async init() {
            this.notifications = [];
            this.limit = 10;
            this.summary = { totalCount: 0 };
        },
        async search() {
            try {
                const { skip, limit, params } = this;
                const { summary, notifications } = await api.v1.center.notifications.getNotifications({ headers: { skip, limit }, params });

                this.summary = summary;
                this.notifications = notifications;
            } catch (error) {
                this.$handleError(error);
            }
        },
        changePage(page) {
            this.$emit("page", page);
        },
        showBoard(item) {
            this.$router.push(`${this.$route.path}/${item._id}`);
        },
    },
};
</script>

<template>
    <review-form :purchase="value" @input="$emit('init')" v-if="reviewable">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
    </review-form>
</template>

<script>
import { initPurchase, PURCHASE_PURCHASE_STATUSES } from "@/assets/variables";

import ReviewForm from "@/components/client/shop/reviews/review-form.vue";

export default {
    components: {
        ReviewForm,
    },
    props: {
        value: { type: Object, default: initPurchase }, // purchase
    },
    computed: {
        reviewable() {
            const hasNoReview = !this.value?._review;
            const isCompleted = this.value.purchaseStatus == PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value;

            return hasNoReview && isCompleted;
        },
    },
};
</script>

<style></style>

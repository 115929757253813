<template>
    <v-card tile>
        <div class="py-16px">
            <v-row align-md="center" class="ma-n8px">
                <v-col cols="auto" class="pa-8px">
                    <v-card style="overflow: hidden">
                        <router-link v-bind="{ to }">
                            <v-img v-bind="{ src }" width="78" height="78">
                                <template #placeholder>
                                    <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                </template>
                            </v-img>
                        </router-link>
                    </v-card>
                </v-col>
                <v-col v-if="mode == 'list'" class="pa-8px">
                    <div class="txt txt--xs mb-4px mb-md-8px mb-lg-4px">
                        <v-row align="center" class="row--xs">
                            <v-col cols="12" sm="auto">
                                <span :class="statusText?.includes?.('배송완료') ? 'primary--text' : 'txt--dark'">{{ statusText }}</span>
                            </v-col>
                            <v-col cols="auto" class="d-none d-sm-flex d-md-none d-lg-flex">
                                <v-divider vertical class="h-12px" />
                            </v-col>
                            <v-col cols="12" sm="auto">
                                <span>상품주문번호 : {{ value?.purchaseNo }}</span>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="txt txt--sm txt--dark font-weight-bold mb-4px" v-if="value?.product?.flags?.length">{{ value?.product.flags.map((text) => `[${text}]`).join(" ") }}</div>
                    <router-link v-bind="{ to }">
                        <div class="txt txt--sm txt--dark mb-12px">{{ value?.product?.name }}</div>
                    </router-link>
                    <v-row align="center" class="row--xxs font-size-18 font-size-md-20">
                        <v-col cols="auto">
                            <strong class="d-inline-flex align-center"> {{ (value?.salePrice * value?.amount).format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                        </v-col>
                        <v-col cols="auto" class="d-flex">
                            <span class="txt txt--xs txt--dark">·</span>
                        </v-col>
                        <v-col cols="auto" class="d-flex">
                            <span class="txt txt--xs txt--dark">{{ value?.amount }}개</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="mode == 'view'" class="pa-8px">
                    <div class="txt txt--xs mb-4px mb-md-8px">상품주문번호 : {{ value?.purchaseNo }}</div>
                    <div class="txt txt--sm txt--dark font-weight-bold mb-4px" v-if="value?.product?.flags?.length">{{ value?.product.flags.map((text) => `[${text}]`).join(" ") }}</div>
                    <router-link v-bind="{ to }">
                        <div class="txt txt--sm txt--dark mb-12px">{{ value?.product?.name }}</div>
                    </router-link>
                    <v-row class="row--xxs">
                        <v-col v-if="value._option || value._supply || value._related" cols="12" sm="auto">
                            <v-chip v-if="value._option" x-small outlined color="grey">옵션</v-chip>
                            <v-chip v-if="value._supply" x-small outlined color="grey">추가</v-chip>
                            <v-chip v-if="value._related" x-small outlined color="grey">추가</v-chip>
                        </v-col>
                        <v-col cols="12" sm="">
                            <span class="txt txt--xs d-sm-inline-block pt-sm-4px">{{ $decode__productOptionName(option) }}</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="$slots['actions']" cols="12" md="auto" class="pa-8px">
                    <div class="w-md-120px">
                        <slot name="actions" />
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-divider v-if="mode == 'list'" />
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: () => ({}) }, // purchase
        mode: { type: String, default: "list" },
        withQuantity: { type: Boolean, default: false },
    },
    computed: {
        to() {
            let _id = this.value?.product?._id;
            if (this.value?.product?.variation?.enabled) {
                _id = this.value?.product?.variation?.head?._id || _id;
            }

            return `/shop/products/${_id}`;
        },
        src() {
            let { thumb } = this.value?.product || {};
            if (thumb) return thumb?.url;
        },
        statusText() {
            return this.$getStatusText(this.value);
        },
        option() {
            let option = this.value?.name || "";
            if (this.withQuantity) option += ` / ${this.value?.amount}개`;
            return option;
        },
    },
};
</script>

<style></style>

<template>
    <div>
        <tit-wrap-line noDivider title="결제 정보" />
        <vertical-form-table v-model="form" v-bind="{ items }" mode="view" />
    </div>
</template>

<script>
import { attrs_input, attrs_switch, initOrder } from "@/assets/variables";

import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

const paymentMethods = {
    card: { value: "card", text: "신용카드" },
    kakao: { value: "kakao", text: "카카오페이" },
    account: { value: "account", text: "무통장입금" },
};

export default {
    components: {
        TitWrapLine,
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: initOrder }, // order
    },
    data: () => ({
        form: initOrder(),

        mode: "view",
        loading: false,

        attrs_switch,
    }),
    computed: {
        items() {
            const items = [];

            items.push({ term: "결제금액", data: (this.form?.totalPrice?.format?.() || 0) + "원" });
            items.push({ term: "결제방법", data: paymentMethods[this.form?.paymentMethod]?.text || "-" });
            if (this.form?.pointPrice) items.push({ term: "사용적립금", data: (this.form?.pointPrice?.format?.() || 0) + "원" });

            items.push({ term: "상품금액", data: (this.form?.productPrice?.format?.() || 0) + "원" });
            if (this.form?.canceledProductPrice) items.push({ term: "취소상품금액", data: (this.form?.canceledProductPrice?.format?.() || 0) + "원" });
            if (this.form?.discountPrice) items.push({ term: "상품할인", data: (this.form?.discountPrice?.format?.() || 0) + "원" });
            if (this.form?.levelDiscountPrice) items.push({ term: "등급할인", data: (this.form?.levelDiscountPrice?.format?.() || 0) + "원" });
            if (this.form?.canceledDiscountPrice) items.push({ term: "취소상품할인", data: (this.form?.canceledDiscountPrice?.format?.() || 0) + "원" });
            if (this.form?.couponPrice) items.push({ term: "쿠폰할인", data: (this.form?.couponPrice?.format?.() || 0) + "원" });
            items.push({ term: "배송비", data: ((this.form?.deliveryPrice + this.form?.servicePrice)?.format?.() || 0) + "원" });
            if (this.form?.islandPrice) items.push({ term: "추가배송비(도서산간)", data: (this.form?.islandPrice?.format?.() || 0) + "원" });

            return items.map((item) => ({ ...attrs_input, ...item }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        init() {
            this.sync();
            this.mode = "view";
        },
        sync() {
            this.form = initOrder(this.value);
        },
    },
};
</script>

<style></style>

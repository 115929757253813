<template>
    <v-card color="grey lighten-5" v-bind="{ loading }">
        <div class="pa-20px">
            <v-row align="center" justify="center" class="row--sm">
                <v-col cols="12" sm="auto">
                    <v-row align="center" class="row--xs">
                        <v-col cols="auto">
                            <v-select v-model="query.searchKey" :items="searchKeys" dense outlined hide-details clearable placeholder="전체" class="w-120px" v-bind="{ loading }" />
                        </v-col>
                        <v-col cols="">
                            <v-text-field v-model="query.searchValue" dense outlined hide-details placeholder="검색어를 입력하세요" v-bind="{ loading }" @keydown.enter="emit" />
                        </v-col>
                        <v-col cols="12" sm="auto">
                            <v-btn v-bind="{ ...btn_primary3, ...$attrs, loading }" class="w-100 w-sm-80px" @click="emit">검색</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
import { btn_primary3 } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ...query,
    searchKey: query.searchKey || null,
    searchValue: query.searchValue || null,
});

const searchKeys = [
    { text: "제목", value: "subject" },
    { text: "내용", value: "content" },
];

export default {
    props: {
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        btn_primary3,

        query: initQuery(),

        searchKeys,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query;

            if (!query.searchKey) delete query.searchKey;
            if (!query.searchValue) delete query.searchValue;

            this.$router.push({ query });
        },
    },
};
</script>

<style>
</style>
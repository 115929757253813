var render = function render(){
  var _vm$product, _vm$product$flags, _vm$product2, _vm$product2$brand, _vm$product3, _vm$product3$brand, _vm$product4, _vm$product5, _vm$product5$brand, _vm$product6, _vm$product6$brand, _vm$product7, _vm$product8, _vm$product8$price, _vm$product8$price$fo, _vm$salePrice, _vm$salePrice$format;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "before__no-background fill-height",
    attrs: {
      "tile": "",
      "color": "transparent",
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "py-16px"
  }, [_c('v-row', {
    staticClass: "ma-n8px",
    attrs: {
      "align-md": "center"
    }
  }, [_vm.$slots['select'] ? _c('v-col', {
    staticClass: "px-8px pt-8px pb-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_vm._t("select")], 2) : _vm._e(), _c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "disabled": _vm.isSoldOut,
      "rounded": "md",
      "flat": ""
    }
  }, [_vm.isLink ? _c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [_c('v-img', _vm._b({
    attrs: {
      "width": "78",
      "height": "78"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }], null, false, 2495918059)
  }, 'v-img', {
    src: _vm.src
  }, false))], 1) : [_c('v-img', _vm._b({
    attrs: {
      "width": "78",
      "height": "78"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  }, 'v-img', {
    src: _vm.src
  }, false))]], 2)], 1), _c('v-col', {
    staticClass: "pa-8px"
  }, [_c('v-card', {
    attrs: {
      "color": "transparent",
      "disabled": _vm.isSoldOut,
      "flat": ""
    }
  }, [_vm.isSoldOut ? _c('div', {
    staticClass: "mb-4px mb-md-12px"
  }, [_c('v-chip', {
    attrs: {
      "x-small": "",
      "color": "grey"
    }
  }, [_vm._v("품절")])], 1) : _vm._e(), (_vm$product = _vm.product) !== null && _vm$product !== void 0 && (_vm$product$flags = _vm$product.flags) !== null && _vm$product$flags !== void 0 && _vm$product$flags.length ? _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
  }, [_vm._v(_vm._s(_vm.product.flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")))]) : _vm._e(), _c('div', {
    staticClass: "txt txt--sm txt--dark mb-12px"
  }, [_vm.isLink ? _c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [(_vm$product2 = _vm.product) !== null && _vm$product2 !== void 0 && (_vm$product2$brand = _vm$product2.brand) !== null && _vm$product2$brand !== void 0 && _vm$product2$brand.name ? _c('span', [_vm._v("[" + _vm._s(((_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : (_vm$product3$brand = _vm$product3.brand) === null || _vm$product3$brand === void 0 ? void 0 : _vm$product3$brand.name) || "") + "]")]) : _vm._e(), _vm._v(" " + _vm._s((_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : _vm$product4.name) + " ")]) : [(_vm$product5 = _vm.product) !== null && _vm$product5 !== void 0 && (_vm$product5$brand = _vm$product5.brand) !== null && _vm$product5$brand !== void 0 && _vm$product5$brand.name ? _c('span', [_vm._v("[" + _vm._s(((_vm$product6 = _vm.product) === null || _vm$product6 === void 0 ? void 0 : (_vm$product6$brand = _vm$product6.brand) === null || _vm$product6$brand === void 0 ? void 0 : _vm$product6$brand.name) || "") + "]")]) : _vm._e(), _vm._v(" " + _vm._s((_vm$product7 = _vm.product) === null || _vm$product7 === void 0 ? void 0 : _vm$product7.name) + " ")]], 2), _vm.discountRate ? _c('div', {
    staticClass: "font-size-14 text-decoration-line-through grey--text text--lighten-1"
  }, [_vm._v(_vm._s((_vm$product8 = _vm.product) === null || _vm$product8 === void 0 ? void 0 : (_vm$product8$price = _vm$product8.price) === null || _vm$product8$price === void 0 ? void 0 : (_vm$product8$price$fo = _vm$product8$price.format) === null || _vm$product8$price$fo === void 0 ? void 0 : _vm$product8$price$fo.call(_vm$product8$price)) + "원")]) : _vm._e(), _c('div', {
    staticClass: "d-flex align-center font-size-18 font-size-md-20 font-size-lg-24"
  }, [_vm.discountRate ? _c('strong', {
    staticClass: "secondary--text mr-8px"
  }, [_vm._v(" " + _vm._s(_vm.discountRate) + "% ")]) : _vm._e(), _c('strong', {
    staticClass: "d-inline-flex align-center"
  }, [_vm._v(_vm._s((_vm$salePrice = _vm.salePrice) === null || _vm$salePrice === void 0 ? void 0 : (_vm$salePrice$format = _vm$salePrice.format) === null || _vm$salePrice$format === void 0 ? void 0 : _vm$salePrice$format.call(_vm$salePrice))), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])])], 1), _vm.$slots['actions'] ? _c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px"
  }, [_vm._t("actions")], 2)]) : _vm._e()], 1)], 1), !_vm.hideDivider ? _c('v-divider') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <component :is="SkinComponent" v-bind="{ value }" color="transparent" />
</template>
<script>
import { initForm } from "@/assets/variables";

export default {
    props: {
        value: { type: initForm, default: () => ({}) },
    },
    computed: {
        SkinComponent() {
            return () => import(`@/components/client/form/skin/${this.value.code}/view.vue`);
        },
    },
};
</script>

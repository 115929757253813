<template>
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon-svg icon-funds">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79988 3.90305C2.97478 4.3156 2.75 4.74677 2.75 5C2.75 5.25323 2.97478 5.6844 3.79988 6.09695C4.58011 6.48706 5.71047 6.75 7 6.75C8.28952 6.75 9.41989 6.48706 10.2001 6.09695C11.0252 5.6844 11.25 5.25323 11.25 5C11.25 4.74677 11.0252 4.3156 10.2001 3.90305C9.41989 3.51294 8.28952 3.25 7 3.25C5.71047 3.25 4.58011 3.51294 3.79988 3.90305ZM3.12906 2.56141C4.15847 2.04671 5.5281 1.75 7 1.75C8.4719 1.75 9.84153 2.04671 10.8709 2.56141C11.8555 3.05369 12.75 3.87252 12.75 5C12.75 6.12748 11.8555 6.94631 10.8709 7.43859C9.84153 7.95329 8.4719 8.25 7 8.25C5.5281 8.25 4.15847 7.95329 3.12906 7.43859C2.14451 6.94631 1.25 6.12748 1.25 5C1.25 3.87252 2.14451 3.05369 3.12906 2.56141Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75 4.25V5H2H1.25V4.25H2.75ZM12 5L12.75 5.00003V8.5C12.75 9.62748 11.8555 10.4463 10.8709 10.9386C9.84152 11.4533 8.47189 11.75 7 11.75C5.5281 11.75 4.15846 11.4533 3.12905 10.9386C2.14451 10.4463 1.25 9.62747 1.25 8.5V5H2H2.75V8.5C2.75 8.75323 2.97478 9.18439 3.79988 9.59694C4.58011 9.98706 5.71048 10.25 7 10.25C8.28951 10.25 9.41988 9.98706 10.2001 9.59694C11.0252 9.18439 11.25 8.75322 11.25 8.5V5.00003L12 5ZM12 5L12.75 5.00003V4.25H11.25V5.00003L12 5Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75 7.75V8.5H2H1.25V7.75H2.75ZM12 8.5L12.75 8.50003V12C12.75 13.1275 11.8555 13.9463 10.8709 14.4386C9.84152 14.9533 8.47189 15.25 7 15.25C5.5281 15.25 4.15846 14.9533 3.12905 14.4386C2.14451 13.9463 1.25 13.1275 1.25 12V8.5H2H2.75V12C2.75 12.2532 2.97478 12.6844 3.79988 13.0969C4.58011 13.4871 5.71048 13.75 7 13.75C8.28951 13.75 9.41988 13.4871 10.2001 13.0969C11.0252 12.6844 11.25 12.2532 11.25 12V8.50003L12 8.5ZM12 8.5L12.75 8.50003V7.75H11.25V8.50003L12 8.5Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75 11.25V12H2H1.25V11.25H2.75ZM12 12L12.75 12V15.5C12.75 16.6275 11.8555 17.4463 10.8709 17.9386C9.84152 18.4533 8.47189 18.75 7 18.75C5.5281 18.75 4.15846 18.4533 3.12905 17.9386C2.14451 17.4463 1.25 16.6275 1.25 15.5V12H2H2.75V15.5C2.75 15.7532 2.97478 16.1844 3.79988 16.5969C4.58011 16.9871 5.71048 17.25 7 17.25C8.28951 17.25 9.41988 16.9871 10.2001 16.5969C11.0252 16.1844 11.25 15.7532 11.25 15.5V12L12 12ZM12 12L12.75 12V11.25H11.25V12L12 12Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75 14.75V15.5H2H1.25V14.75H2.75ZM12 15.5L12.75 15.5V19C12.75 20.1275 11.8555 20.9463 10.8709 21.4386C9.84152 21.9533 8.47189 22.25 7 22.25C5.5281 22.25 4.15846 21.9533 3.12905 21.4386C2.14451 20.9463 1.25 20.1275 1.25 19V15.5H2H2.75V19C2.75 19.2532 2.97478 19.6844 3.79988 20.0969C4.58011 20.4871 5.71048 20.75 7 20.75C8.28951 20.75 9.41988 20.4871 10.2001 20.0969C11.0252 19.6844 11.25 19.2532 11.25 19V15.5L12 15.5ZM12 15.5L12.75 15.5V14.75H11.25V15.5L12 15.5Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7999 10.9031C12.9748 11.3156 12.75 11.7468 12.75 12C12.75 12.2532 12.9748 12.6844 13.7999 13.0969C14.5801 13.4871 15.7105 13.75 17 13.75C18.2895 13.75 19.4199 13.4871 20.2001 13.0969C21.0252 12.6844 21.25 12.2532 21.25 12C21.25 11.7468 21.0252 11.3156 20.2001 10.9031C19.4199 10.5129 18.2895 10.25 17 10.25C15.7105 10.25 14.5801 10.5129 13.7999 10.9031ZM13.1291 9.56141C14.1585 9.04671 15.5281 8.75 17 8.75C18.4719 8.75 19.8415 9.04671 20.8709 9.56141C21.8555 10.0537 22.75 10.8725 22.75 12C22.75 13.1275 21.8555 13.9463 20.8709 14.4386C19.8415 14.9533 18.4719 15.25 17 15.25C15.5281 15.25 14.1585 14.9533 13.1291 14.4386C12.1445 13.9463 11.25 13.1275 11.25 12C11.25 10.8725 12.1445 10.0537 13.1291 9.56141Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.75 11.25V12H12H11.25V11.25H12.75ZM22 12L22.75 12V15.5C22.75 16.6275 21.8555 17.4463 20.8709 17.9386C19.8415 18.4533 18.4719 18.75 17 18.75C15.5281 18.75 14.1585 18.4533 13.1291 17.9386C12.1445 17.4463 11.25 16.6275 11.25 15.5V12H12H12.75V15.5C12.75 15.7532 12.9748 16.1844 13.7999 16.5969C14.5801 16.9871 15.7105 17.25 17 17.25C18.2895 17.25 19.4199 16.9871 20.2001 16.5969C21.0252 16.1844 21.25 15.7532 21.25 15.5V12L22 12ZM22 12L22.75 12V11.25H21.25V12L22 12Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.75 14.75V15.5H12H11.25V14.75H12.75ZM22 15.5L22.75 15.5V19C22.75 20.1275 21.8555 20.9463 20.8709 21.4386C19.8415 21.9533 18.4719 22.25 17 22.25C15.5281 22.25 14.1585 21.9533 13.1291 21.4386C12.1445 20.9463 11.25 20.1275 11.25 19V15.5H12H12.75V19C12.75 19.2532 12.9748 19.6844 13.7999 20.0969C14.5801 20.4871 15.7105 20.75 17 20.75C18.2895 20.75 19.4199 20.4871 20.2001 20.0969C21.0252 19.6844 21.25 19.2532 21.25 19V15.5L22 15.5ZM22 15.5L22.75 15.5V14.75H21.25V15.5L22 15.5Z" />
    </svg>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-funds {
    width: 24px;
    color: var(--v-primary-base);
}
</style>

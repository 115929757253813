var render = function render(){
  var _vm$statusText, _vm$statusText$includ, _vm$value, _vm$value2, _vm$value2$product, _vm$value2$product$fl, _vm$value3, _vm$value4, _vm$value4$product, _vm$value5, _vm$value6, _vm$value7, _vm$value8, _vm$value9, _vm$value9$product, _vm$value9$product$fl, _vm$value10, _vm$value11, _vm$value11$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "py-16px"
  }, [_c('v-row', {
    staticClass: "ma-n8px",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [_c('v-img', _vm._b({
    attrs: {
      "width": "78",
      "height": "78"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  }, 'v-img', {
    src: _vm.src
  }, false))], 1)], 1)], 1), _vm.mode == 'list' ? _c('v-col', {
    staticClass: "pa-8px"
  }, [_c('div', {
    staticClass: "txt txt--xs mb-4px mb-md-8px mb-lg-4px"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('span', {
    class: (_vm$statusText = _vm.statusText) !== null && _vm$statusText !== void 0 && (_vm$statusText$includ = _vm$statusText.includes) !== null && _vm$statusText$includ !== void 0 && _vm$statusText$includ.call(_vm$statusText, '배송완료') ? 'primary--text' : 'txt--dark'
  }, [_vm._v(_vm._s(_vm.statusText))])]), _c('v-col', {
    staticClass: "d-none d-sm-flex d-md-none d-lg-flex",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-12px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('span', [_vm._v("상품주문번호 : " + _vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.purchaseNo))])])], 1)], 1), (_vm$value2 = _vm.value) !== null && _vm$value2 !== void 0 && (_vm$value2$product = _vm$value2.product) !== null && _vm$value2$product !== void 0 && (_vm$value2$product$fl = _vm$value2$product.flags) !== null && _vm$value2$product$fl !== void 0 && _vm$value2$product$fl.length ? _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
  }, [_vm._v(_vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.product.flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")))]) : _vm._e(), _c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [_c('div', {
    staticClass: "txt txt--sm txt--dark mb-12px"
  }, [_vm._v(_vm._s((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : (_vm$value4$product = _vm$value4.product) === null || _vm$value4$product === void 0 ? void 0 : _vm$value4$product.name))])]), _c('v-row', {
    staticClass: "row--xxs font-size-18 font-size-md-20",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', {
    staticClass: "d-inline-flex align-center"
  }, [_vm._v(" " + _vm._s((((_vm$value5 = _vm.value) === null || _vm$value5 === void 0 ? void 0 : _vm$value5.salePrice) * ((_vm$value6 = _vm.value) === null || _vm$value6 === void 0 ? void 0 : _vm$value6.amount)).format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])]), _c('v-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs txt--dark"
  }, [_vm._v("·")])]), _c('v-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs txt--dark"
  }, [_vm._v(_vm._s((_vm$value7 = _vm.value) === null || _vm$value7 === void 0 ? void 0 : _vm$value7.amount) + "개")])])], 1)], 1) : _vm._e(), _vm.mode == 'view' ? _c('v-col', {
    staticClass: "pa-8px"
  }, [_c('div', {
    staticClass: "txt txt--xs mb-4px mb-md-8px"
  }, [_vm._v("상품주문번호 : " + _vm._s((_vm$value8 = _vm.value) === null || _vm$value8 === void 0 ? void 0 : _vm$value8.purchaseNo))]), (_vm$value9 = _vm.value) !== null && _vm$value9 !== void 0 && (_vm$value9$product = _vm$value9.product) !== null && _vm$value9$product !== void 0 && (_vm$value9$product$fl = _vm$value9$product.flags) !== null && _vm$value9$product$fl !== void 0 && _vm$value9$product$fl.length ? _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
  }, [_vm._v(_vm._s((_vm$value10 = _vm.value) === null || _vm$value10 === void 0 ? void 0 : _vm$value10.product.flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")))]) : _vm._e(), _c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to
  }, false), [_c('div', {
    staticClass: "txt txt--sm txt--dark mb-12px"
  }, [_vm._v(_vm._s((_vm$value11 = _vm.value) === null || _vm$value11 === void 0 ? void 0 : (_vm$value11$product = _vm$value11.product) === null || _vm$value11$product === void 0 ? void 0 : _vm$value11$product.name))])]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_vm.value._option || _vm.value._supply || _vm.value._related ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_vm.value._option ? _c('v-chip', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("옵션")]) : _vm._e(), _vm.value._supply ? _c('v-chip', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("추가")]) : _vm._e(), _vm.value._related ? _c('v-chip', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey"
    }
  }, [_vm._v("추가")]) : _vm._e()], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('span', {
    staticClass: "txt txt--xs d-sm-inline-block pt-sm-4px"
  }, [_vm._v(_vm._s(_vm.$decode__productOptionName(_vm.option)))])])], 1)], 1) : _vm._e(), _vm.$slots['actions'] ? _c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px"
  }, [_vm._t("actions")], 2)]) : _vm._e()], 1)], 1), _vm.mode == 'list' ? _c('v-divider') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
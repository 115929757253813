<template>
    <div class="pa-18px pa-md-24px">
        <v-row align="center" class="row--sm">
            <v-col cols="12" md="4">
                <v-text-field v-model="query['product.name']" placeholder="상품명을 검색하세요" append-icon="mdi-magnify" v-bind="{ ...attrs, loading }" @keydown.enter="search" />
            </v-col>
            <v-col cols="12" md="5">
                <div class="d-flex flex-column flex-sm-row align-center">
                    <v-text-field v-model="query['createdAt'][0]" type="date" v-bind="{ ...attrs, loading }" class="w-100" />
                    <span class="txt txt--xs mx-8px">~</span>
                    <v-text-field v-model="query['createdAt'][1]" type="date" v-bind="{ ...attrs, loading }" class="w-100" />
                </div>
            </v-col>
            <v-col cols="12" md="3">
                <div class="d-flex align-center">
                    <v-select v-model="query['code']" :items="codes" v-bind="{ ...attrs, loading }" />
                    <v-btn v-bind="{ ...btn_primary3, ...$attrs, loading }" class="ml-8px" @click="search">조회</v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { PURCHASE_QUERY_CODES } from "@/assets/variables";
import { btn_primary3 } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ["code"]: query["code"] || null,
    ["product.name"]: query["product.name"] || null,
    ["createdAt"]: JSON.parse(JSON.stringify(query["createdAt"] || [null, null])),
});

const codes = [{ text: "전체", value: null }].concat(Object.values(PURCHASE_QUERY_CODES));

const attrs = {
    outlined: true,
    dense: true,
    "hide-details": true,
};

export default {
    props: {
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        btn_primary3,

        query: initQuery(),
        codes,
        attrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        $route() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        search() {
            let { ...query } = this.query;

            if (!query["code"]) delete query["code"];
            if (!query["product.name"]) delete query["product.name"];
            if (!query["createdAt"].some((item) => !!item)) delete query["createdAt"];
            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tabs-wrap"
  }, [_c('v-btn-toggle', {
    staticClass: "grey darken-4",
    attrs: {
      "mandatory": "",
      "group": ""
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large w-50",
    attrs: {
      "to": "/mypage/coupons",
      "exact-path": ""
    }
  }, [_vm._v(" 보유 쿠폰 ")]), _c('v-btn', {
    staticClass: "v-size--xx-large w-50",
    attrs: {
      "to": "/mypage/coupons/download",
      "exact-path": ""
    }
  }, [_vm._v(" 쿠폰 받기 ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
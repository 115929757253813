var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_vm.coupons.length ? [_c('v-row', {
    attrs: {
      "justify": "end",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v(" 총 "), _c('span', {
    staticClass: "secondary--text"
  }, [_vm._v(_vm._s(_vm.summary.totalCount))]), _vm._v(" 개 ")])])], 1), _c('v-row', [_vm._l(_vm.coupons, function (coupon) {
    return [_c('v-col', {
      key: coupon._id,
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "12",
        "lg": "6"
      }
    }, [_c('shop-coupon-item', _vm._b({}, 'shop-coupon-item', {
      coupon
    }, false))], 1)];
  })], 2), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)] : [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "mb-18px mb-md-24px"
  }, [_c('v-icon', {
    attrs: {
      "size": "60px",
      "color": "primary"
    }
  }, [_vm._v("mdi-ticket-percent")])], 1), _c('div', {
    staticClass: "tit tit--sm"
  }, [_vm._v("받을 수 있는 쿠폰이 없습니다.")]), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "to": "/shop"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("쇼핑 계속하기")])], 1)], 1)], 1)])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
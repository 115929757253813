<template>
    <client-page>
        <mypage-layout title="영수증 신청/발급">
            <!-- <page-section v-for="(order, index) in items" :key="order._id" class="page-section--sm pb-0" :class="{ 'pt-0' : index = 0 }"> -->
            <page-section class="page-section--sm pb-0">
                <template slot="containerImmersive">
                    <tit-wrap-line noDivider>
                        <template slot="titleContents">
                            <h2 class="d-sm-inline-flex align-center">
                                <!-- <span class="tit tit--xs">{{ order?.createdAt?.toDate?.() }}</span> -->
                                <span class="tit tit--xs">2023-02-25</span>
                                <v-divider vertical class="h-12px align-self-center mx-12px mx-md-16px d-none d-sm-inline-block" />
                                <span class="d-block d-sm-inline-block txt txt txt--sm txt--dark pt-4px pt-sm-0">
                                    <!-- <span class="txt txt--sm">주문번호 : {{ order?.orderNo }}</span> -->
                                    <span class="txt txt--sm">주문번호 : 20221220000001</span>
                                </span>
                            </h2>
                        </template>
                    </tit-wrap-line>

                    <div class="border-t border-2 border-dark">
                        <!-- <template v-for="purchase in order.purchases"> -->
                        <template>
                            <!-- <purchase-item :key="purchase._id" :value="purchase"> -->
                            <purchase-item>
                                <template #actions>
                                    <v-row class="row--xs">
                                        <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                            <v-btn v-bind="{ ...btn_secondary, ...$attrs }" block>세금계산서 신청</v-btn>
                                        </v-col>

                                        <!-- <purchase-item-claim :value="purchase" @input="(purchase) => $emit('input', purchase)">
                                            <template #activator="{ attrs, on }">
                                                <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                                    <v-btn v-bind="attrs" v-on="on" block outlined color="grey">취소 신청</v-btn>
                                                </v-col>
                                            </template>
                                        </purchase-item-claim> -->
                                    </v-row>
                                </template>
                            </purchase-item>
                        </template>
                    </div>
                </template>
            </page-section>

            <!-- <div v-if="hasMoreItems" v-intersect.quiet="loadInfinite" class="btn-wrap">
                <v-btn outlined x-large block color="primary" v-bind="{ loading }" @click="loadMore"> 더 보기 </v-btn>
            </div> -->
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { PURCHASE_QUERY_CODES } from "@/assets/variables";
import { btn_secondary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";

import OrderView from "@/components/client/mypage/order/order-view.vue";
import PurchaseItem from "@/components/client/mypage/purchase/purchase-item.vue";
// import PurchaseItemClaim from "@/components/client/mypage/purchase/purchase-item-claim.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        TitWrapLine,
        OrderView,
        PurchaseItem,
        // PurchaseItemClaim,
    },
    data: function() {
        return {
            btn_secondary,

            orders: [],
            purchases: [],

            limit: 10,
            summary: { totalCount: 0 },

            loading: false,
        };
    },
    computed: {
        items() {
            return this.orders.map((order) => ({ ...order, purchases: [...(this.purchases || [])].filter((purchase) => order?._id == purchase?._order) }));
        },

        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit || 0;
        },

        headers() {
            let { page, skip, limit } = this;
            if (this.purchases.length < skip) {
                skip = this.purchases.length;
                limit = limit * page - this.purchases.length;
            }
            return { skip, limit };
        },
        params() {
            let { code, ...query } = this.$route.query;
            return { ...query, ...(PURCHASE_QUERY_CODES[code]?.params || {}) };
        },

        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        hasMoreItems() {
            return this.purchases.length < this.summary.totalCount;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            if (this.page == 1) this.init();
            else this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.loading = false;

                this.orders = [];
                this.purchases = [];
                this.summary = { totalCount: 0 };

                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async search() {
            if (this.loading) return;
            this.loading = true;

            try {
                let { headers, params } = this;
                let { summary, purchases } = await api.v1.me.purchases.gets({
                    headers,
                    params,
                });

                this.summary = summary;
                let orders = purchases.reduce((orders, { order }) => (orders.some((item) => item._id == order._id) ? orders : [...orders, order]), []);
                for (const order of orders) {
                    this.updateOrder(order);
                }
                this.purchases = this.purchases.concat(purchases);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.$nextTick(() => (this.loading = false));
            }
        },

        async updateOrder(order) {
            let index = this.orders.findIndex(({ _id }) => _id == order._id);
            if (0 <= index) this.orders.splice(index, 1, order);
            else this.orders.push(order);
        },

        loadMore() {
            if (this.loading) return;
            let { page = "1", ...query } = this.$route.query;
            query.page = +page + 1;
            this.$router.replace({ query });
        },
        loadInfinite(entries, observer, isIntersecting) {
            if (isIntersecting) this.loadMore();
        },
    },
};
</script>

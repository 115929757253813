<template>
    <div v-if="notification">
        <v-simple-table class="v-data-table--default v-data-table--no-hover w-100">
            <tbody>
                <tr>
                    <th>
                        <span class="break-words">{{ notification.subject }}</span>
                    </th>
                </tr>
                <tr>
                    <td>
                        <div class="divider-group">
                            <ul>
                                <li>작성일 : {{ notification.createdAt.toDate() }}</li>
                            </ul>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="min-h-100px min-h-md-150px" v-html="notification.content.replace(/\n/g, '<br>')"></div>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <div class="btn-wrap">
            <v-row class="row--xs">
                <v-spacer />
                <v-col cols="auto">
                    <v-btn outlined color="primary" @click="$router.go(-1)">리스트</v-btn>
                </v-col>
            </v-row>
        </div>

        <!-- <div role="table" class="board-table board-table--view">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader">
                        {{ notification.subject }}
                    </div>
                </div>
                <div role="row">
                    <div role="cell">
                        <ul class="board-view-data">
                            <li>작성일 : {{ notification.createdAt.toDate() }}</li>
                            <li>조회수 : {{ notification.views }}</li>
                        </ul>
                    </div>
                </div>
                <div v-show="false" role="row">
                    <div role="cell">
                        <div class="board-view-file">
                            <span class="head">
                                <svg
                                    version="1.1"
                                    id="레이어_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 409 513.6"
                                    style="enable-background:new 0 0 409 513.6;"
                                    xml:space="preserve"
                                >
                                    <path
                                        d="M41.5,452.8v-353c0-11,9-20,20-20h60v-19c0-11,9-20,20-20c11,0,20,9,20,20v141c0,11-9,20-20,20c-11,0-20-9-20-20v-82h-40v82
                                    c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60v-122h145.9c11,0,20,9,20,20v353c0,11-9,20-20,20H61.5C50.5,472.8,41.5,463.8,41.5,452.8
                                    M85.3,39.8H61.5c-33.1,0-60,26.9-60,60v353c0,33.1,26.9,60,60,60h285.9c33.1,0,60-26.9,60-60v-353c0-33.1-26.9-60-60-60H197.7
                                    c-8.5-22.8-30.5-39-56.2-39S93.9,17,85.3,39.8"
                                    />
                                </svg>
                                첨부파일 :
                            </span>
                            <ul v-show="false" class="lists">
                                <li><a href="">파일제목_01.jpg,</a></li>
                                <li><a href="">파일제목_02.jpg,</a></li>
                                <li><a href="">파일제목_03.jpg,</a></li>
                                <li><a href="">파일제목_04.jpg,</a></li>
                                <li><a href="">파일제목_05.jpg</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div role="row">
                    <div role="cell">
                        <div class="board-content">
                            <div v-html="notification.content.replace(/\n/g, '<br>')"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="false" role="table" class="board-table board-table--preview">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader">
                        이전글
                    </div>
                    <div role="cell">
                        <div class="tit"><a href="">이전 게시글 제목입니다.</a></div>
                    </div>
                </div>
                <div role="row">
                    <div role="columnheader">
                        이전글
                    </div>
                    <div role="cell">
                        <div class="tit"><a href="">다음 게시글 제목입니다.</a></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="board-buttons">
            <div class="left">
                <a class="button button--border" @click="$router.go(-1)"><span>리스트</span></a>
            </div>
            <div v-show="false" class="right">
                <a class="button button--border" href=""><span>수정</span></a>
                <a class="button button--border" href=""><span>답글</span></a>
                <a class="button button--primary" href=""><span>삭제</span></a>
            </div>
        </div> -->
    </div>
</template>

<script>
import api from "@/api";
export default {
    components: {},
    computed: {
        _notification() {
            return this.$route?.params?._notification;
        },
    },
    data() {
        return {
            notification: null,
        };
    },
    mounted() {
        if (this._notification) this.init();
    },
    methods: {
        async init() {
            try {
                var { notification } = await api.v1.center.notifications.getNotification({ _id: this._notification });
                this.notification = notification;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    watch: {
        _notification() {
            this.init();
        },
    },
};
</script>

var render = function render(){
  var _ref2, _ref2$format, _ref3, _ref3$format;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "persistent": "",
      "title": "리뷰 작성",
      "width": "600"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm"
        }, [_c('v-col', [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          }
        }, 'v-btn', Object.assign({}, _vm.btn_tertiary, _vm.$attrs), false), [_vm._v("취소")])], 1), _c('v-col', [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": ""
          },
          on: {
            "click": _vm.save
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v(_vm._s(_vm.isCreate ? "작성" : "수정"))])], 1)], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', [_c('page-section', {
    staticClass: "page-section--xs pt-0"
  }, [_c('review-purchase-item', _vm._b({}, 'review-purchase-item', {
    product: _vm.product,
    purchase: _vm.purchase
  }, false))], 1), _c('v-divider'), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "리뷰포인트 정보"
    }
  }), _c('v-card', {
    attrs: {
      "rounded": "md",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px"
  }, [_c('div', [_c('v-row', {
    staticClass: "txt txt--xs",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 리뷰 작성 시 ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium grey--text text--darken-4"
  }, [_vm._v(" " + _vm._s((_ref2 = Math.floor(_vm.salePrice / 1000) * 10) === null || _ref2 === void 0 ? void 0 : (_ref2$format = _ref2.format) === null || _ref2$format === void 0 ? void 0 : _ref2$format.call(_ref2)) + "p 적립")])])], 1)], 1), _c('div', {
    staticClass: "mt-8px"
  }, [_c('v-row', {
    staticClass: "txt txt--xs",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 포토리뷰 작성 시 ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium grey--text text--darken-4"
  }, [_vm._v(" " + _vm._s((_ref3 = Math.floor(_vm.salePrice / 1000) * 10 * 2) === null || _ref3 === void 0 ? void 0 : (_ref3$format = _ref3.format) === null || _ref3$format === void 0 ? void 0 : _ref3$format.call(_ref3)) + "p 적립")])])], 1)], 1)])])], 1), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    scopedSlots: _vm._u([{
      key: "titleContents",
      fn: function () {
        return [_c('h3', {
          staticClass: "txt txt--xs txt--dark font-weight-medium"
        }, [_vm._v("상품 만족도 "), _c('span', {
          staticClass: "red--text"
        }, [_vm._v("*")])])];
      },
      proxy: true
    }])
  }), _c('v-card', {
    attrs: {
      "rounded": "md",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-32px"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium"
  }, [_vm._v("이 상품에 대해서 얼마나 만족하시나요?")]), _c('div', {
    staticClass: "font-size-20 font-size-md-36 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.form.star || 0))]), _c('v-rating', {
    attrs: {
      "half-increments": "",
      "color": "grey darken-4",
      "background-color": "grey darken-4"
    },
    model: {
      value: _vm.form.star,
      callback: function ($$v) {
        _vm.$set(_vm.form, "star", $$v);
      },
      expression: "form.star"
    }
  }), _c('div', {
    staticClass: "txt txt--xs txt--light"
  }, [_vm._v("별점을 선택해 주세요.")])], 1)])])], 1), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    scopedSlots: _vm._u([{
      key: "titleContents",
      fn: function () {
        return [_c('h3', {
          staticClass: "txt txt--xs txt--dark font-weight-medium"
        }, [_vm._v("리뷰 작성 "), _c('span', {
          staticClass: "red--text"
        }, [_vm._v("*")])])];
      },
      proxy: true
    }])
  }), _c('v-textarea', _vm._b({
    attrs: {
      "placeholder": "리뷰를 작성해 주세요."
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    scopedSlots: _vm._u([{
      key: "titleContents",
      fn: function () {
        return [_c('h3', {
          staticClass: "txt txt--xs txt--dark font-weight-medium"
        }, [_vm._v("사진 첨부"), _c('span', {
          staticClass: "txt--light font-size-12 font-weight-regular pl-8px"
        }, [_vm._v("사진은 최대 20MB 이하의 JPG, PNG, GIF 파일 10장까지 첨부 가능합니다.")])])];
      },
      proxy: true
    }])
  }), _c('form-files', {
    model: {
      value: _vm.form.images,
      callback: function ($$v) {
        _vm.$set(_vm.form, "images", $$v);
      },
      expression: "form.images"
    }
  }), _c('div', {
    staticClass: "pt-12px"
  }, [_c('v-card', {
    attrs: {
      "rounded": "md",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px"
  }, [_c('div', {
    staticClass: "font-size-12 red--text"
  }, [_vm._v("*사진 첨부 시 포인트를 추가 적립해 드리며, 제품과 무관한 사진 등록 시 포인트 차감의 사유가 될 수 있습니다.")])])])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    staticClass: "v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer",
    attrs: {
      "items": _vm.notifications,
      "headers": _vm.headers,
      "dense": "",
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "no-data-text": "등록된 공지사항이 없습니다",
      "mobile-breakpoint": "768"
    },
    on: {
      "click:row": function (any, _ref) {
        var item = _ref.item;
        return _vm.showBoard(item);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value,
            item = _ref2.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.subject`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-truncate",
          attrs: {
            "data-idx": "5"
          }
        }, [_vm._v(_vm._s(item.subject))])];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "mt-20px mt-40px"
        }, [_c('v-card', {
          attrs: {
            "color": "grey lighten-5"
          }
        }, [_c('div', {
          staticClass: "pa-20px"
        }, [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "auto"
          }
        }, [_c('v-row', {
          staticClass: "row--xs",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-select', {
          staticClass: "w-120px",
          attrs: {
            "dense": "",
            "outlined": "",
            "hide-details": "",
            "item-text": "text",
            "item-value": "type",
            "placeholder": "선택"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": ""
          }
        }, [_c('v-text-field', {
          attrs: {
            "dense": "",
            "outlined": "",
            "hide-details": "",
            "placeholder": "검색어를 입력하세요"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "w-100 w-sm-80px"
        }, 'v-btn', Object.assign({}, _vm.btn_primary3, _vm.$attrs), false), [_vm._v("검색")])], 1)], 1)], 1)], 1)], 1)])], 1), _c('div', {
          staticClass: "pagination-wrap"
        }, [_c('v-pagination', {
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 11,
            "color": "primary"
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })], 1)];
      },
      proxy: true
    }], null, true)
  });

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "작성 가능한 리뷰"
    }
  }), _c('div', {
    staticClass: "border-t border-2 border-dark"
  }, [_vm._l(_vm.items, function (purchase) {
    return _c('purchase-item', {
      key: purchase._id,
      attrs: {
        "value": purchase
      },
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function () {
          return [_c('review-form', _vm._b({
            on: {
              "input": _vm.search
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref) {
                var attrs = _ref.attrs,
                  on = _ref.on;
                return [_c('v-row', {
                  staticClass: "row--xs"
                }, [_c('v-col', {
                  staticClass: "mw-100 flex-grow-1 flex-shrink-0",
                  attrs: {
                    "cols": "4",
                    "md": "12"
                  }
                }, [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "block": ""
                  },
                  on: {
                    "click": function ($event) {
                      $event.preventDefault();
                    }
                  }
                }, 'v-btn', Object.assign({}, _vm.btn_secondary, {
                  attrs
                }), false), on), [_vm._v("리뷰 작성")])], 1)], 1)];
              }
            }], null, true)
          }, 'review-form', {
            purchase
          }, false))];
        },
        proxy: true
      }], null, true)
    });
  }), !_vm.items.length ? [_c('div', {
    staticClass: "txt txt--sm text-center py-20px py-md-30px"
  }, [_vm._v("리뷰 적립이 가능한 주문이 없습니다")]), _c('v-divider')] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "rounded": "",
      "color": "grey lighten-5"
    }
  }, [_c('purchase-status-navigation', _vm._b({}, 'purchase-status-navigation', {
    loading: _vm.loading,
    hideOverall: _vm.hideOverall
  }, false)), !_vm.hideFilters ? [_c('v-divider', {
    staticClass: "grey lighten-4"
  }), _c('purchase-status-filter', _vm._b({}, 'purchase-status-filter', {
    loading: _vm.loading
  }, false))] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
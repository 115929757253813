<template>
    <div>
        <tit-wrap-line noDivider title="작성 가능한 리뷰">
            <!-- <v-btn outlined color="grey" :to="{ path: '/mypage/orders', query: { reviewable: true } }" class="v-size--xx-small">
                <span class="red--text">{{ summary.totalCount }}</span> 개 전체 보기
            </v-btn> -->
        </tit-wrap-line>

        <div class="border-t border-2 border-dark">
            <purchase-item v-for="purchase in items" :key="purchase._id" :value="purchase">
                <template #actions>
                    <review-form v-bind="{ purchase }" @input="search">
                        <template #activator="{ attrs, on }">
                            <v-row class="row--xs">
                                <v-col cols="4" md="12" class="mw-100 flex-grow-1 flex-shrink-0">
                                    <v-btn v-bind="{ ...btn_secondary, attrs }" v-on="on" block @click.prevent>리뷰 작성</v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </review-form>
                </template>
            </purchase-item>
            <template v-if="!items.length">
                <div class="txt txt--sm text-center py-20px py-md-30px">리뷰 적립이 가능한 주문이 없습니다</div>
                <v-divider />
            </template>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { btn_secondary } from "@/assets/variables";

import ReviewForm from "@/components/client/shop/reviews/review-form.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import PurchaseItem from "@/components/client/mypage/purchase/purchase-item.vue";

export default {
    components: {
        ReviewForm,
        TitWrapLine,
        PurchaseItem,
    },
    data: () => ({
        btn_secondary,

        purchases: [],

        limit: 3,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        items() {
            return [...this.purchases].map((item, index) => ({ index: this.summary.totalCount - index, ...item }));
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    methods: {
        async init() {
            this.purchases = [];
            this.summary = { totalCount: 0 };

            this.loading = false;
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { limit } = this;
                var { summary, purchases } = await api.v1.me.purchases.gets({
                    headers: { limit },
                    params: { reviewable: true },
                });

                this.summary = summary;
                this.purchases = purchases;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>

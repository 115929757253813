var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "title": "영수증 신청/발급"
    }
  }, [_c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('template', {
    slot: "containerImmersive"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": ""
    }
  }, [_c('template', {
    slot: "titleContents"
  }, [_c('h2', {
    staticClass: "d-sm-inline-flex align-center"
  }, [_c('span', {
    staticClass: "tit tit--xs"
  }, [_vm._v("2023-02-25")]), _c('v-divider', {
    staticClass: "h-12px align-self-center mx-12px mx-md-16px d-none d-sm-inline-block",
    attrs: {
      "vertical": ""
    }
  }), _c('span', {
    staticClass: "d-block d-sm-inline-block txt txt txt--sm txt--dark pt-4px pt-sm-0"
  }, [_c('span', {
    staticClass: "txt txt--sm"
  }, [_vm._v("주문번호 : 20221220000001")])])], 1)])], 2), _c('div', {
    staticClass: "border-t border-2 border-dark"
  }, [[_c('purchase-item', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--xs"
        }, [_c('v-col', {
          staticClass: "mw-100 flex-grow-1 flex-shrink-0",
          attrs: {
            "cols": "4",
            "md": "12"
          }
        }, [_c('v-btn', _vm._b({
          attrs: {
            "block": ""
          }
        }, 'v-btn', Object.assign({}, _vm.btn_secondary, _vm.$attrs), false), [_vm._v("세금계산서 신청")])], 1)], 1)];
      },
      proxy: true
    }])
  })]], 2)], 1)], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
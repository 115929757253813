<template>
    <v-row no-gutters>
        <v-col cols="12" xl="">
            <product-item v-bind="{ product, isSoldOut }" hideDivider isLink>
                <template #select>
                    <v-simple-checkbox v-bind="{ ...checkbox_primary, ...$attrs }" :value="isSelected" class="d-inline-block" @click.prevent.stop.capture="toggle" />
                </template>
            </product-item>
        </v-col>
        <v-col cols="auto" class="d-none d-xl-block"> <v-divider vertical /> </v-col>
        <v-col cols="12" :style="$vuetify.breakpoint.xlOnly ? 'flex: 0 0 27.97202797202797%; max-width: 27.97202797202797%;' : ''">
            <div class="px-xl-12px py-xl-16px">
                <v-row class="row--sm">
                    <template v-for="(item, index) in carts">
                        <v-col :key="`item-${index}`" cols="12" :class="{ 'mt-n4px': index != 0 }">
                            <v-card tile :color="$vuetify.breakpoint.lgAndDown ? 'grey lighten-5' : 'transparent'">
                                <div class="pa-10px pa-xl-0">
                                    <v-row class="row--xs">
                                        <v-col>
                                            <template v-if="item.isShippingOutdated">
                                                <v-tooltip bottom color="error" close-delay="100" max-width="200px">
                                                    <template v-slot:activator="{ attrs, on }">
                                                        <v-icon small color="error" class="mr-1" v-bind="attrs" v-on="on"> mdi-alert-circle-outline </v-icon>
                                                    </template>
                                                    <v-card-subtitle class="py-1 px-0">[만료배송정책]</v-card-subtitle>
                                                    <v-card-text class="px-0 pb-1"> 배송정책이 변경되었습니다. 주문수정 버튼을 눌러 배송정책을 변경해주세요. </v-card-text>
                                                </v-tooltip>
                                            </template>
                                            <span class="txt txt--xs">{{ formatCart(item) }}</span>
                                        </v-col>
                                        <v-col v-if="item?._supply || item?._related || (carts?.length || 0) - (carts__supply?.length || 0) - (carts__related?.length || 0) - 1" cols="auto">
                                            <v-icon dense color="grey lighten-3" @click="$emit('remove', item)">mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col v-if="index != carts.length - 1" :key="`item-${index}-divider`" cols="12" class="d-none d-xl-block"> <v-divider /> </v-col>
                    </template>
                    <v-col>
                        <cart-form :value="carts" v-bind="{ _product: product?._id }" @search="$emit('search')">
                            <template #activator="{ attrs, on }">
                                <div class="text-right text-xl-left">
                                    <v-btn v-bind="{ ...btn_tertiary, ...attrs, disabled: isSoldOut }" v-on="on" class="v-size--xx-small w-100 w-md-auto">주문수정</v-btn>
                                </div>
                            </template>
                        </cart-form>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col cols="auto" class="d-none d-xl-block"> <v-divider vertical /> </v-col>
        <v-col cols="12" xl="2">
            <!-- S: Mobile -->
            <div class="d-xl-none py-16px">
                <div>
                    <v-row no-gutters justify="space-between" class="txt txt--xs txt--dark font-weight-medium">
                        <v-col cols="auto"> 상품금액 </v-col>
                        <v-col cols="auto">
                            <strong>{{ totalPrice.format() }}원</strong>
                        </v-col>
                    </v-row>
                </div>
                <div class="mt-8px">
                    <v-row no-gutters justify="space-between" class="txt txt--xs">
                        <v-col cols="auto"> {{ shippingCodeText }} </v-col>
                        <template v-if="shippingChargeType != SHIPPING_OPTION_CHARGE_TYPES.PAY_ON_DELIVERY.value">
                            <v-col cols="auto">
                                <span class="font-weight-medium">{{ (servicePrice + deliveryPrice).format() }}원</span>
                            </v-col>
                        </template>
                    </v-row>
                </div>
            </div>
            <!-- E: Mobile -->

            <!-- S: PC -->
            <div class="d-none d-xl-flex align-center h-100 pa-12px py-md-16px">
                <v-row class="row--xxs text-center">
                    <v-col cols="12">
                        <div class="txt txt--xs">상품금액</div>
                        <strong class="font-size-16 break-all">{{ totalPrice.format() }}<small class="font-size-14 font-weight-regular">원</small></strong>
                    </v-col>
                    <v-col cols="12">
                        <div class="txt txt--xs txt--light">
                            {{ shippingCodeText }}
                            <template v-if="shippingChargeType != SHIPPING_OPTION_CHARGE_TYPES.PAY_ON_DELIVERY.value">
                                <br />
                                {{ (servicePrice + deliveryPrice).format() }}원
                            </template>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <!-- E: PC -->
        </v-col>
        <v-col cols="auto" class="d-none d-xl-block"> <v-divider vertical /> </v-col>
        <v-col cols="12"> <v-divider /> </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { btn_tertiary, checkbox_primary } from "@/assets/variables";
import { SHIPPING_CODES, SHIPPING_OPTION_CHARGE_TYPES } from "@/assets/variables";

import LikeBtn from "@/components/client/like/like-btn.vue";
import CartForm from "@/components/client/mypage/cart/cart-form.vue";
import ProductItem from "@/components/client/mypage/product/product-item.vue";

export default {
    components: {
        LikeBtn,
        CartForm,
        ProductItem,
    },
    props: {
        value: { type: Array, default: () => [] }, // selected
        carts: { type: Array, default: () => [] },
        product: { type: Object, default: () => ({}) },
        shippingCode: { type: String, default: SHIPPING_CODES.GENERAL_COURIER_DELIVERY.value },
        shippingChargeType: { type: String, default: null },
    },
    data: () => ({
        btn_tertiary,
        checkbox_primary,
        SHIPPING_OPTION_CHARGE_TYPES,
    }),
    computed: {
        ...mapGetters(["shippingCodes", "getSalePrice"]),
        isSoldOut() {
            return !this.product?.sells;
        },
        isSelected() {
            const _selected = this.value.map(({ _id }) => _id);
            return !this.carts.some(({ _id }) => !_selected.includes(_id));
        },
        totalPrice() {
            return this.carts.reduce((total, item) => total + this.getSalePrice(item) * item.amount, 0);
        },
        servicePrice() {
            return this.$getServicePrice(this.carts);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts);
        },
        carts__supply() {
            return this.carts.filter(({ _supply }) => _supply);
        },
        carts__related() {
            return this.carts.filter(({ _related }) => _related);
        },
        shippingCodeText() {
            return this.shippingCodes.find(({ value }) => value == this.shippingCode)?.text;
        },
    },
    methods: {
        toggle() {
            const _selected = this.value.map(({ _id }) => _id);
            const pushbales = this.carts.filter(({ _id }) => !_selected.includes(_id));
            if (pushbales.length) this.$emit("input", [...this.value, ...pushbales]);
            else {
                const _carts = this.carts.map(({ _id }) => _id);
                const selected = this.value.filter(({ _id }) => !_carts.includes(_id));
                this.$emit("input", selected);
            }
        },
        formatCart(cart) {
            let text = "";

            if (cart.name) text += `${this.$decode__productOptionName(cart.name)} `;
            if (cart.amount) text += `/ ${cart.amount.format()}개 `;
            if (cart.name != "본품") {
                const salePrice = this.getSalePrice(cart);
                if (0 < salePrice) text += `(+${(salePrice * cart.amount).format()}원)`;
                if (salePrice < 0) text += `(${(salePrice * cart.amount).format()}원)`;
            }
            return text.trim();
        },
    },
};
</script>

<style></style>

var render = function render(){
  var _vm$items;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "찜한 상품"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-small",
    attrs: {
      "outlined": "",
      "color": "grey",
      "to": "/mypage/likes"
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.summary.totalCount))]), _vm._v(" 개 전체 보기 ")])], 1), _c('div', {
    staticClass: "border-t border-2 border-dark"
  }, [_vm._l(_vm.items, function (item) {
    return _c('like-product-item', _vm._b({
      key: item._id,
      attrs: {
        "hideActions": ""
      }
    }, 'like-product-item', {
      item
    }, false));
  }), _c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !((_vm$items = _vm.items) !== null && _vm$items !== void 0 && _vm$items.length),
      expression: "!items?.length"
    }],
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm text-center py-20px py-md-30px"
  }, [_vm._v(" 찜한 상품이 없습니다 ")]), _c('v-divider')], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }